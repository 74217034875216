import { graphql } from "gatsby"
import "normalize.css/normalize.css"
import React, { useRef } from "react"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"
import BlockContent from "../components/block-content"
import HeroImage from "../components/hero-image"
import SEO from "../components/seo"
import "../styles/global.css"
import styles from "./page.module.css"

export const query = graphql`
	query PageTemplateQuery($id: String!) {
		page: sanityPage(id: { eq: $id }) {
			title
			blocks: _rawBody
			mainImage {
				alt
				asset {
					mainImage: fluid(maxWidth: 1920) {
						src
						srcSet
						aspectRatio
						base64
					}
				}
			}
		}
	}
`

const Page = (props) => {
	const { location, data } = props
	const contentRef = useRef(null)
	const { blocks, mainImage } = data.page
	const { alt: imageAlt } = mainImage
	const src = mainImage.asset.mainImage?.src
	const aspectRatio = mainImage.asset.mainImage?.aspectRatio
	const base64 = mainImage.asset.mainImage?.base64

	return (
		<>
			<SEO url={location.pathname} image={src} imageAlt={imageAlt} />
			<HeroImage
				preserveImage={false}
				base64={base64}
				aspectRatio={aspectRatio}
				imageAlt={imageAlt}
				imageSrc={src}
				contentRef={contentRef}
			/>
			<div ref={contentRef}>
				<Section className={styles.section}>
					<Container breakpoint="fullhd">
						<Heading size={2} renderAs="h1" className={styles.heading}>
							{data.page.title}
						</Heading>
						<BlockContent blocks={blocks} />
					</Container>
				</Section>
			</div>
		</>
	)
}

export default Page
